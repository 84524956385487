import { createActionsButton } from '@UIkit/components/buttons';
import { documentsModal } from '@Edi/methods/methods';
import { MODAL_SIZE } from '@UIkit/components/modal';

/**
 * Object for document action methods
 * Method witch create button for document action must have name as template:
 * "create{ActionName}ActionButton",
 * {ActionName} is lowercase letters with capital first,
 * example: "createRefreshActionButton" where "Refresh" is convert from action "REFRESH", or
 * "createRouteRejectActionButton" where "RouteReject" is convert from action "ROUTE_REJECT"
 *
 * @authors Daria M., Vsevolod L.
 */
Ext.namespace('edi.document.actions');
Object.assign(edi.document.actions, {
	/**
	 * action button config
	 */
	buttonsConfig: {},

	/**
	 * create edit action button
	 * @param    {String}    moduleName
	 * @param    {Object}    data
	 * @param    {String}    documentNumber
	 * @param    {String}    customLabel     custom text constant to display on button
	 * @param    {String}    customId
	 * @param    {Object}    moduleAddConf	module additional config
	 */
	createEditActionButton: function (moduleName, data, documentNumber, customLabel, customId, moduleAddConf) {
		return createActionsButton({
			text: edi.i18n.getMessage(customLabel ? customLabel : 'document.edit.document'),
			glyph: edi.constants.ICONS.EDIT,
			handler: function () {
				edi.core.openModule(moduleName, data, documentNumber, true, customId, moduleAddConf);
			}
		});
	},

	/**
	 * create convert to draft action button
	 * @param    {Object}    document        document data
	 * @param    {Object}    tab             ext element that should show loading mask
	 * @param    {Function}  beforeDraft     Function that should be called before convert action
	 * @param    {Function}  success         Function that will be called on success convertation of document
	 * @param    {Function}  failure         Function that will be called on failure convertation of document
	 * @param    {Function}  draftAction     Function that will be called instead of standard push action
	 * @param    {String}    customLabel     custom text constant to display on button
	 */
	createConvertToDraftActionButton: function (
		document,
		tab,
		beforeDraft,
		success,
		failure,
		draftAction,
		customLabel
	) {
		return createActionsButton({
			text: edi.i18n.getMessage(customLabel ? customLabel : 'document.convert.to.draft.document'),
			glyph: edi.constants.ICONS.DRAFT,
			handler: function () {
				tab.setLoading();
				success =
					'function' == typeof success
						? success
						: function () {
								tab.setLoading(false);
								edi.events.documents.fireEvent('change', {
									id: document.id
								});
						  };
				failure =
					'function' == typeof failure
						? failure
						: edi.document.actions.defaultFailureHandler(tab, 'document.error.in.convert.to.draft.process');
				draftAction =
					'function' == typeof draftAction
						? draftAction
						: function () {
								var postData = {};
								var stringified = Ext.encode(postData);
								edi.rest.sendRequest(
									edi.utils.formatString(
										edi.rest.services.DOCUMENTS.SEND.PUT,
										{
											documentId: document.id
										},
										true
									),
									'PUT',
									stringified,
									success,
									failure
								);
						  };
				if ('function' == typeof beforeDraft) {
					beforeDraft(draftAction);
				} else {
					draftAction();
				}
			}
		});
	},

	/**
	 * Show message that document was created if details module is not opening
	 * @param isOpeningDetails
	 * @param msg
	 */
	showDocumentCreatedMessage: function (isOpeningDetails, msg) {
		if (!isOpeningDetails) {
			edi.core.showInfo(msg || 'document.create.success');
		}
	},

	/**
	 * create refresh action button
	 * @param    {Function}     refreshHandler     handler that will be called on button click
	 */
	createRefreshActionButton: function (refreshHandler) {
		return createActionsButton({
			text: edi.i18n.getMessage('paging.refresh'),
			glyph: edi.constants.ICONS.REVERT_RELOAD,
			handler: refreshHandler
		});
	},

	/**
	 * create download template button
	 * @param    {Object}     handler
	 */
	createDownloadTemplateButton: function (handler) {
		return createActionsButton({
			text: edi.i18n.getMessage('fhp.reports.template.download'),
			glyph: edi.constants.ICONS.FILE_DOWNLOAD,
			handler: handler
		});
	},

	/**
	 * Change module tab title
	 * @param    {Object}    tab
	 * @param    {String}    title
	 * @param    {Boolean}    isEdit
	 * @param    {Boolean}    isCopy
	 */
	changeTabTitle: function (tab, title, isEdit, isCopy) {
		if (tab.isDestroyed) {
			return;
		}
		var module = edi.core.getModule(tab.module.modName);
		let titleText;
		if (typeof module.getModuleTitle === 'function') {
			titleText = module.getModuleTitle({ number: title, isEdit, module });
		} else {
			titleText = edi.i18n.getMessage(module.title + (isEdit ? '.edit' : isCopy ? '.copy' : ''));
			if (typeof title === 'string' || typeof title === 'number') {
				titleText += ' ' + title;
			}
		}

		tab.module.title = titleText;
		tab.breadCrumbItem && 'function' == typeof tab.breadCrumbItem.setText
			? tab.breadCrumbItem.setText(tab.module.title)
			: null;
		tab.tab && 'function' == typeof tab.tab.setText ? tab.tab.setText(tab.module.title) : null;
	},

	/**
	 * Handles grid action on selected documents scope
	 * @param options
	 */
	documentGridActionProcess: function (options) {
		var data = [],
			grid,
			handler,
			moduleData,
			readyDocuments,
			notReadyDocuments,
			notProcessedDocuments,
			handlerAction;
		options = 'object' == typeof options ? options : {};
		/**
		 * Fires backend request for document processing
		 * @param record
		 * @param callback
		 */
		var processDocument = function (record, callback) {
			var id = record.get(options.idProperty),
				postData = {};
			var processUrl = options.processUrl,
				processMethod = options.processMethod,
				urlParams = {
					documentId: id
				};
			if (id) {
				if (options.processProperties) {
					if ('function' == typeof options.processProperties) {
						Object.assign(postData, options.processProperties(record));
					} else {
						for (var i in options.processProperties) {
							if (options.processProperties.hasOwnProperty(i)) {
								if ('function' == typeof options.processProperties[i]) {
									Object.assign(postData, options.processProperties[i](record));
								} else {
									postData[i] = options.processProperties[i];
								}
							}
						}
					}
				}
				if ('function' == typeof options.processUrl) {
					processUrl = options.processUrl(record);
				}
				if ('function' == typeof options.processMethod) {
					processMethod = options.processMethod(record);
				}
				if ('function' == typeof options.processUrlParamCollector) {
					urlParams = options.processUrlParamCollector(record);
				}
				edi.rest.sendRequest(
					edi.utils.formatString(processUrl, urlParams),
					processMethod,
					Ext.encode(postData),
					callback,
					function (data) {
						if (options.preventSingleError) {
							callback && 'function' == typeof callback ? callback(true, data) : null;
						} else {
							edi.document.actions.defaultFailureHandler(undefined, undefined, function () {
								callback && 'function' == typeof callback ? callback(true, data) : null;
							})(data);
						}
					}
				);
			} else {
				callback && 'function' == typeof callback ? callback(true) : null;
			}
		};
		/**
		 * Default action handler
		 * @param record
		 * @param grid
		 * @param callback
		 * @param failure
		 * @returns {Function}
		 */
		var defaultHandler = function (record, grid, callback, failure) {
			return function () {
				var finishCallback = function (failed, data, cert, poa, silent) {
					var gridSelModel = grid.getSelectionModel();
					if (true === failed) {
						edi.core.logMessage(
							'Error occurred during ' +
								options.action +
								' of document ' +
								record.get(options.idProperty),
							'warn'
						);
						'function' == typeof failure ? failure(record, data, cert, poa, silent) : null;
					} else if (options.processEvent && options.processEvent.object && options.processEvent.event) {
						if ('function' == typeof options.processEvent.object.fireEvent) {
							options.processEvent.object.fireEvent(options.processEvent.event, record.getData());
						} else if (
							edi.events[options.processEvent.object] &&
							'function' == typeof edi.events[options.processEvent.object].fireEvent
						) {
							edi.events[options.processEvent.object].fireEvent(
								options.processEvent.event,
								record.getData()
							);
						}
					}
					gridSelModel.deselect(record);
					callback && 'function' == typeof callback ? callback(record, data, cert, poa, silent) : null;
				};
				processDocument(
					record,
					'function' == typeof options.processDocumentCallback
						? function (signData) {
								options.processDocumentCallback(record, signData, finishCallback);
						  }
						: finishCallback
				);
			};
		};
		var defaults = {
			grid: null,
			handler: defaultHandler,
			action: null,
			beforeStart: null,
			idProperty: 'id',
			actionCheckCallback: function (record) {
				return edi.action.isAvailable(options.action, edi.action.getDocumentData(record, options.parent));
			},
			notProcessedMsg: 'documents.that.were.not.processed',
			loadingMessage: 'document.action.started',
			confirmTitle: 'document.action.title',
			confirmQuestion: 'document.action.confirm.question',
			confirmQuestionActionPrefix: 'document.action.',
			confirmAction: false,
			createList: edi.document.actions.createDocumentsList,
			notReadyTitle: 'warn',
			notProcessedTitle: 'documents.not.processed.title',
			notReadyMsg: 'documents.that.will.not.be.processed',
			noValidDocumentsMsg: 'documents.error.not.selected.any.valid.document',
			processProperties: {},
			processUrl:
				edi.rest.services.DOCUMENTS && edi.rest.services.DOCUMENTS.SEND
					? edi.rest.services.DOCUMENTS.SEND.PUT
					: undefined,
			processMethod: 'PUT',
			processUrlParamCollector: function (record) {
				return {
					documentId: record.get('id')
				};
			},
			processDocumentCallback: null,
			additionalHandler: null,
			defaultEventObj: 'documents',
			processEvent: null,
			completeEvent: {
				object: 'documents',
				event: 'change',
				data: undefined
			},
			forceCompleteEvent: false,
			completeCallback: null,
			parent: null
		};
		Ext.applyIf(options, defaults);
		grid = options.grid;
		handler = options.handler;
		moduleData = options.moduleData;
		if (grid && handler && moduleData && options.action) {
			var gridSelModel = grid.getSelectionModel();
			data = options.initialData || gridSelModel.getSelection();
			if (!data || !data.length) {
				return;
			}

			let convertDocumentsData = function (documents) {
				return documents.map(function (doc) {
					doc.data.error =
						edi.utils.formatComplexServerError(doc.errorData) ||
						edi.utils.formatComplexServerError(doc.errorResponse);
					return doc;
				});
			};

			var afterStart = function () {
				data = options.data || data;
				if (!data || !data.length) {
					return;
				}

				readyDocuments = [];
				handlerAction = null;
				notReadyDocuments = [];
				notProcessedDocuments = options.noProcessDocs || [];
				for (let i = 0; i < data.length; i++) {
					const rec = data[i];
					rec.checkResult = edi.action.isAvailableExtended(
						options.action,
						edi.action.getDocumentData(rec, options.parent)
					);
					if (options.actionCheckCallback(rec)) {
						readyDocuments.push(rec);
						if (handler && !options.groupProcessedUrl) {
							handlerAction = handler(
								rec,
								grid,
								'function' == typeof handlerAction
									? handlerAction
									: function () {
											//это колбэк success
											const signedDocs = [...readyDocuments];
											const notSignedDocs = [...notReadyDocuments];
											//копируем результаты в новые массивы, т.к. исходные мутируют/очищаются где-то в событиях ниже
											moduleData.tab.setLoading(false);
											if (notProcessedDocuments.length) {
												if (options.dontShowNotProccessed !== true) {
													notProcessedDocuments = convertDocumentsData(notProcessedDocuments);
													documentsModal(
														{
															title: edi.i18n.getMessage(options.notProcessedTitle),
															text: edi.utils.formatString(
																edi.i18n.getMessage(options.notProcessedMsg),
																[]
															),
															width: MODAL_SIZE.widthLarge,
															model: edi.models.getModel('DOCUMENTS_MODAL'),
															columns: edi.columns.get('documents_modal_with_error')
														},
														notProcessedDocuments,
														moduleData
													);
												}
											}
											if (
												(notProcessedDocuments.length !== readyDocuments.length ||
													options.forceCompleteEvent) &&
												options.completeEvent &&
												options.completeEvent.object &&
												options.completeEvent.event
											) {
												if ('function' == typeof options.completeEvent.object.fireEvent) {
													options.completeEvent.object.fireEvent(
														options.completeEvent.event,
														options.completeEvent.data
													);
												} else if (
													edi.events[options.completeEvent.object] &&
													'function' ==
														typeof edi.events[options.completeEvent.object].fireEvent
												) {
													edi.events[options.completeEvent.object].fireEvent(
														options.completeEvent.event,
														options.completeEvent.data
													);
												}
											}
											'function' == typeof options.completeCallback
												? options.completeCallback(signedDocs, notSignedDocs)
												: null;
									  },
								function (record, errorData) {
									//это колбэк failure
									if (record.ignored !== true) {
										const recordData = Ext.clone(record);
										recordData.errorData = errorData;
										notProcessedDocuments.push(recordData);
									}
								},
								moduleData, //в альтернативной версии тут передают options (искать по слову signingPricatRejectDocs)
								options.defaultEventObj,
								options.additionalHandler,
								function () {
									//Это хук для получения состояния процесса подписания, можно дополнять любыми необходимыми данными
									const signedDocs = [...readyDocuments];
									const notSignedDocs = [...notReadyDocuments];
									// копируем результаты в новые массивы, т.к. исходные мутируют/очищаются где-то в событиях выше
									return { signedDocs, notSignedDocs };
								}
							);
						}
					} else {
						notReadyDocuments.push(rec);
						gridSelModel.deselect(rec);
					}
				}
				if (options.groupProcessedUrl && readyDocuments.length) {
					handlerAction = function () {
						var listValues =
							'function' == typeof options.genParamsMethod
								? options.genParamsMethod(readyDocuments)
								: readyDocuments.map(function (item) {
										return item.get('id');
								  });
						edi.rest.sendRequest(
							options.groupProcessedUrl,
							options.processMethod ? options.processMethod : 'PUT',
							Ext.encode(listValues),
							function (successData) {
								if (
									options.completeEvent &&
									options.completeEvent.object &&
									options.completeEvent.event
								) {
									if ('function' == typeof options.completeEvent.object.fireEvent) {
										options.completeEvent.object.fireEvent(
											options.completeEvent.event,
											options.completeEvent.data
										);
									} else if (
										edi.events[options.completeEvent.object] &&
										'function' == typeof edi.events[options.completeEvent.object].fireEvent
									) {
										edi.events[options.completeEvent.object].fireEvent(
											options.completeEvent.event,
											options.completeEvent.data
										);
									}
								}
								'function' == typeof options.completeCallback
									? options.completeCallback(readyDocuments, successData)
									: null;
							},
							function (failureData) {
								'function' == typeof options.groupActionFailureMethod
									? options.groupActionFailureMethod(failureData, readyDocuments)
									: edi.core.showError(edi.utils.formatComplexServerError(failureData));
							},
							function () {
								moduleData.tab.setLoading(false);
								gridSelModel.deselect(readyDocuments);
							}
						);
					};
				}
				moduleData.tab.setLoading(edi.i18n.getMessage(options.loadingMessage));
				var continueAction = function () {
					if (options.confirmAction) {
						documentsModal(
							{
								title: edi.i18n.getMessage(options.confirmTitle),
								text: edi.utils.formatString(edi.i18n.getMessage(options.confirmQuestion), [
									edi.i18n.getMessage(options.confirmQuestionActionPrefix + options.action)
								]),
								model: options.confirmGridModel ?? edi.models.getModel('DOCUMENTS_MODAL'),
								columns: options.confirmGridColumns ?? edi.columns.get('documents_modal'),
								confirm: true,
								okButtonConfig: {
									text: edi.i18n.getMessage(options.okButtonTitle || options.confirmTitle),
									glyph: edi.constants.ICONS[options.action]
								}
							},
							readyDocuments,
							moduleData,
							handlerAction
						);
					} else {
						'function' == typeof handlerAction ? handlerAction() : moduleData.tab.setLoading(false);
					}
				};

				const exitWithoutAction = function () {
					gridSelModel.deselectAll();
					moduleData.tab.setLoading(false);
					if (typeof options.noChangesCallback === 'function') {
						options.noChangesCallback(notReadyDocuments);
					}
				};

				if (options.skipInfoAndConfirm === true) {
					if (notReadyDocuments.length && !handlerAction) {
						exitWithoutAction();
					} else {
						continueAction();
					}
				} else {
					if (notReadyDocuments.length && 'function' == typeof handlerAction) {
						documentsModal(
							{
								title: edi.i18n.getMessage(options.notReadyTitle),
								text: edi.utils.formatString(edi.i18n.getMessage(options.notReadyMsg), []),
								model: edi.models.getModel('DOCUMENTS_MODAL'),
								columns: edi.columns.get('documents_modal')
							},
							notReadyDocuments,
							moduleData,
							continueAction
						);
					} else if (notReadyDocuments.length && !handlerAction) {
						documentsModal(
							{
								title: edi.i18n.getMessage(options.notReadyTitle),
								text: edi.i18n.getMessage(options.noValidDocumentsMsg),
								model: edi.models.getModel('DOCUMENTS_MODAL'),
								columns: edi.columns.get('documents_modal')
							},
							notReadyDocuments,
							moduleData,
							function () {
								exitWithoutAction();
							}
						);
					} else {
						continueAction();
					}
				}
			};
			if ('function' == typeof options.beforeStart) {
				options.beforeStart(data, afterStart, options);
			} else {
				afterStart();
			}
		}
	},
	/**
	 * Creates html code for documents list, used for modal messages
	 * @param    {Array}    docs    collection of documents records
	 * @return   {String}
	 */
	createDocumentsList: function (docs) {
		var htmlOut = '<table class="documents-list">',
			hasErrors,
			body = '<tbody>',
			header =
				'<thead><th>' +
				edi.i18n.getMessage('documents.list.document.number') +
				'</th><th>' +
				edi.i18n.getMessage('documents.list.document.type') +
				'</th><th>' +
				edi.i18n.getMessage('documents.list.document.status') +
				'</th>';
		if (docs && docs.length) {
			for (var i = 0; i < docs.length; i++) {
				body +=
					'<tr' +
					(i % 2 ? " class='alt-row'" : '') +
					(docs[i].tooltip ? " data-qtip='" + edi.i18n.getMessage(docs[i].tooltip) + "'" : '') +
					'>';
				if (docs[i].tooltip) {
					delete docs[i].tooltip; //We need to remove this data, to prevent it display on any futher confirm action
				}
				var docNumber = docs[i] && docs[i].get ? docs[i].get('number') || docs[i].get('id') : '';
				body += '<td>' + docNumber + '</td>';

				var docType = docs[i] && docs[i].get ? docs[i].get('type') : null;
				if (docType) {
					body += '<td>' + edi.renderers.doctype(docType) + '</td>';
				} else {
					body += '<td>' + '</td>';
				}

				var docState = docs[i] && docs[i].get ? docs[i].get('state') : null;
				if (docState) {
					body += '<td>' + edi.renderers.status(docState, undefined, docs[i]) + '</td>';
				} else {
					body += '<td>' + '</td>';
				}
				if (docs[i].errorData || docs[i].errorResponse) {
					body +=
						"<td colspan='2' class='error-description-cell'>" +
							edi.utils.formatComplexServerError(docs[i].errorData) ||
						edi.utils.formatComplexServerError(docs[i].errorResponse) + '</td>';
					hasErrors = true;
					//We need to remove this data, to prevent it display on any futher confirm action
					delete docs[i].errorData;
					delete docs[i].errorResponse;
				}
				body += '</tr>';
			}
		}
		if (hasErrors) {
			header += "<th colspan='2'>" + edi.i18n.getMessage('documents.list.document.error') + '</th></thead>';
		} else {
			header += '</thead>';
		}
		body += '</tbody>';
		htmlOut += header + body + '</table>';
		return htmlOut;
	},
	/**
	 * Creates html code for items list, used for modal messages
	 * @param    {Array}    items    collection of item records
	 * @param    {Object}   map      properties map
	 * @return   {String}
	 */
	createItemsList: function (items, map) {
		var i, j;
		map = map || {
			id: {
				title: 'column.id',
				renderer: null
			},
			name: {
				title: 'column.name',
				renderer: null
			}
		};
		var htmlOut = '<table class="documents-list"><thead>';
		for (i in map) {
			if (map.hasOwnProperty(i)) {
				htmlOut += edi.utils.formatString("<th colspan='{0}'>{1}</th>", [
					map[i].colspan || 1,
					edi.i18n.getMessage(map[i].title)
				]);
			}
		}
		htmlOut += '</thead><tbody>';
		if (items && items.length) {
			for (i = 0; i < items.length; i++) {
				htmlOut += '<tr>';
				for (j in map) {
					if (map.hasOwnProperty(j)) {
						htmlOut += edi.utils.formatString("<td colspan='{0}'>{1}</td>", [
							map[j].colspan || 1,
							'function' == typeof map[j].renderer
								? map[j].renderer(items[i].get(j), null, items[i])
								: edi.i18n.getMessage(items[i].get(j))
						]);
					}
				}
				htmlOut += '</tr>';
			}
		}
		htmlOut += '</tbody></table>';
		return htmlOut;
	},
	/**
	 * Returns number of signs needed for current document
	 * @param    {Object}    data               document header data
	 * @param    {String}    [side]               force side selection without checking by organization id
	 * @returns  {Number}
	 */
	getSignCount: function (data, side) {
		var count = 0,
			docType = data.type;
		if (
			side &&
			side != edi.constants.CONTRACT_SIDES.CONSUMER &&
			side != edi.constants.CONTRACT_SIDES.SUPPLIER &&
			side != edi.constants.CONTRACT_SIDES.FACTOR
		) {
			side = undefined;
		}
		if (data) {
			if (
				!side &&
				docType == edi.constants.DOCUMENT_TYPES.FP_CONVENTION_AGREEMENT_TERMINATION &&
				data.factor &&
				data.supplier
			) {
				side =
					data.factor.id == edi.core.getUserOrgID()
						? edi.constants.CONTRACT_SIDES.FACTOR
						: edi.constants.CONTRACT_SIDES.SUPPLIER;
				count = parseInt(edi.utils.getAttributeByName(data.attributes, 'countSignature_' + side), 10) || 0;
			} else if (!side && docType == edi.constants.DOCUMENT_TYPES.FP_ACT_OF_AGREEMENT && data.factor) {
				side =
					data.factor.id == edi.core.getUserOrgID()
						? edi.constants.CONTRACT_SIDES.FACTOR
						: edi.constants.CONTRACT_SIDES.SUPPLIER;
				count = parseInt(edi.utils.getAttributeByName(data.attributes, 'countSignature_' + side), 10) || 0;
			} else if (
				!side &&
				docType == edi.constants.DOCUMENT_TYPES.FP_NOTICE_OF_AGREEMENT_TERMINATION &&
				data.factor
			) {
				side =
					data.factor.id == edi.core.getUserOrgID()
						? edi.constants.CONTRACT_SIDES.FACTOR
						: data.fromOrg.id == edi.core.getUserOrgID()
						? edi.constants.CONTRACT_SIDES.SUPPLIER
						: edi.constants.CONTRACT_SIDES.CONSUMER;
				count = parseInt(edi.utils.getAttributeByName(data.attributes, 'countSignature_' + side), 10) || 0;
			} else {
				var direction = edi.utils.getDocumentDirection(data.toOrg, data.fromOrg, data.factor);
				side = side ? side : edi.document.actions.getSignSide(docType, direction);
				if (side) {
					count = parseInt(edi.utils.getAttributeByName(data.attributes, 'countSignature_' + side), 10) || 0;
				}
			}
		}
		return count;
	},

	getSignMadeCount: function (data, side) {
		var count = null,
			docType = data.type;
		if (
			side &&
			side != edi.constants.CONTRACT_SIDES.CONSUMER &&
			side != edi.constants.CONTRACT_SIDES.SUPPLIER &&
			side != edi.constants.CONTRACT_SIDES.FACTOR
		) {
			side = undefined;
		}
		if (data) {
			if (
				!side &&
				docType == edi.constants.DOCUMENT_TYPES.FP_CONVENTION_AGREEMENT_TERMINATION &&
				data.factor &&
				data.supplier
			) {
				side =
					data.factor.id == edi.core.getUserOrgID()
						? edi.constants.CONTRACT_SIDES.FACTOR
						: edi.constants.CONTRACT_SIDES.SUPPLIER;
			} else if (!side && docType == edi.constants.DOCUMENT_TYPES.FP_ACT_OF_AGREEMENT && data.factor) {
				side =
					data.factor.id == edi.core.getUserOrgID()
						? edi.constants.CONTRACT_SIDES.FACTOR
						: edi.constants.CONTRACT_SIDES.SUPPLIER;
			} else if (
				!side &&
				docType == edi.constants.DOCUMENT_TYPES.FP_NOTICE_OF_AGREEMENT_TERMINATION &&
				data.factor
			) {
				side =
					data.factor.id == edi.core.getUserOrgID()
						? edi.constants.CONTRACT_SIDES.FACTOR
						: data.fromOrg.id == edi.core.getUserOrgID()
						? edi.constants.CONTRACT_SIDES.SUPPLIER
						: edi.constants.CONTRACT_SIDES.CONSUMER;
			} else {
				var direction = edi.utils.getDocumentDirection(data.toOrg, data.fromOrg, data.factor);
				side = side ? side : edi.document.actions.getSignSide(docType, direction);
			}
			if (side) {
				count = edi.utils.getAttributeByName(data.attributes, 'countSigned_' + side);
				if (Ext.isNumeric(count)) {
					count = parseInt(count, 10) || 0;
				}
			}
		}
		return count;
	},
	/**
	 * Returns side constant for current document
	 * @param docType
	 * @param direction
	 */
	getSignSide: function (docType, direction) {
		let side;
		const cfg = edi.constants.SIGNATURE_SIDE[direction];
		if (docType && direction && edi.constants.DIRECTIONS.UNKNOWN !== direction && cfg) {
			if (cfg[edi.constants.CONTRACT_SIDES.CONSUMER]?.some((it) => it === docType)) {
				side = edi.constants.CONTRACT_SIDES.CONSUMER;
			} else if (cfg[edi.constants.CONTRACT_SIDES.SUPPLIER]?.some((it) => it === docType)) {
				side = edi.constants.CONTRACT_SIDES.SUPPLIER;
			} else if (cfg[edi.constants.CONTRACT_SIDES.FACTOR]?.some((it) => it === docType)) {
				side = edi.constants.CONTRACT_SIDES.FACTOR;
			}
		}
		return side;
	},

	/**
	 * Returns button icon for passed action constant
	 * @param action
	 * @returns {string}
	 */
	getActionIcon: function (action) {
		var icon = '';
		if (edi.constants.DOCUMENT_ACTIONS.CREATE == action) {
			icon = edi.constants.ICONS.CREATE;
		} else if (edi.constants.DOCUMENT_ACTIONS.READ == action) {
			icon = edi.constants.ICONS.READ;
		} else if (edi.constants.DOCUMENT_ACTIONS.EDIT == action) {
			icon = edi.constants.ICONS.EDIT;
		} else if (edi.constants.DOCUMENT_ACTIONS.DELETE == action) {
			icon = edi.constants.ICONS.DELETE;
		} else if (
			edi.constants.ADDITIONAL_ACTIONS_ICONS_HANDLERS &&
			edi.constants.ADDITIONAL_ACTIONS_ICONS_HANDLERS.length
		) {
			icon = edi.constants.ADDITIONAL_ACTIONS_ICONS_HANDLER(action);
		}
		return icon;
	},
	/**
	 * Creates default failure handler
	 * @param maskParent
	 * @param defaultMessage
	 * @param showErrorHandler
	 * @returns {Function}
	 */
	defaultFailureHandler: function (maskParent, defaultMessage, showErrorHandler) {
		return function (data) {
			maskParent && 'function' == typeof maskParent.setLoading ? maskParent.setLoading(false) : null;
			var msg = edi.utils.formatComplexServerError(data, defaultMessage);
			if (msg) {
				edi.core.showError(
					msg,
					'function' == typeof showErrorHandler
						? function () {
								showErrorHandler(data);
						  }
						: undefined
				);
			} else if ('function' == typeof showErrorHandler) {
				showErrorHandler(data);
			}
		};
	}
});
